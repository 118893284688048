/*header*/
.fa.sm-block {
	display: none
}

.header-section.header-fixed {
	position: fixed;
	z-index: 999;
	width: 100%;
	-webkit-transition: padding .4s;
	transition: padding .4s;
	left: 0;
	top: 0;
}

.brand-logo-wrapper {
	position: relative;
	width: 230px;
	display: block;
	border-right: 1px solid #eee;
}

.brand-logo-wrapper figure {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	align-content: center;
	padding: 5px;
}

.brand-logo-wrapper figure.brand-logo-xs {
	width: 100%;
	display: none;
}

.brand-logo-wrapper figure.brand-logo-xs img {
	width: 36px;
}

.header-section.closed .brand-logo-wrapper {
	width: 60px;
}

.header-section.closed .brand-logo-wrapper figure.brand-logo-xs {
	display: block;
}

.header-section.header-fixed.closed .brand-logo-wrapper figure.brand-logo-md {
	display: none;
}

.drawer-toggle {
	position: absolute;
	top: 11px;
	right: -14px;
	cursor: pointer;
	z-index: 99;
	font-size: 20px;
	width: 28px;
	height: 28px;
	background-color: #fff;
	text-align: center;
	line-height: 24px;
	border-radius: 100%;
	color: grey;
	box-shadow: 0 4px 8px #db772c40;
	border: 1px solid #fff;
}

/* .drawer-toggle:hover {
	background-color: #f7d185;
	color: #db772c;
	-webkit-box-shadow: 0 0 5px 0px rgb(121 119 119 / 30%);
	box-shadow: 0 4px 8px #db772c40;
} */

.navigation-bar {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	position: relative;
	-ms-flex-direction: row;
	-webkit-flex-direction: row;
	flex-direction: row;
	background-color: #fff;
	min-height: 50px;
	z-index: 9;
	padding: 0;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: flex-start;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #eee;
	flex-wrap: wrap;
}

.brand-logo {
	margin: 0 auto;
	display: block;
	width: 150px;
}

.brand-text {
	vertical-align: middle;
	display: inline-block;
	font-weight: bold;
	margin: 3px 0 0 8px;
	color: #222;
}

.navigation-menu-bar {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: calc(100% - 230px);
}

.header-section.header-fixed.header-fixed-admin .navigation-menu-bar {
	width: calc(100% - 230px);
}

.navigation-menu-bar h2 {
	margin: 0;
	font-size: 1.4rem;
}

.info-navbar-menu li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 15px;
}

.info-navigation-menu.nav-right {
	margin-left: auto
}

.header-project {
	background-color: var(--theme-light-color);
	padding: 7px 15px;
	border-radius: 20px;
	font-size: 1rem;
	cursor: pointer;
}

.header-project:hover {
	background-color: var(--theme-light-color);
}

.project-dropdown-menu {
	position: absolute;
	top: 50px;
	left: 0px;
	min-width: 250px;
	max-width: 250px;
	max-height: 250px;
	overflow-y: auto;
	background-color: #fff;
	width: 100%;
	box-shadow: 2px 0px 12px 0 rgba(0, 0, 0, 0.12);
	border-radius: 8px 8px;
	opacity: 0;
	visibility: hidden;
	transition: .4s ease all;
	transform: translateY(-30px);
	z-index: 9;
}

.project-dropdown-menu.show::after {
	position: absolute;
	background-color: transparent;
	content: "";
	top: -15px;
	left: 30px;
	/* height: 13px; */
	width: 20px;
	border-bottom: 15px solid #fff;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
}

.project-dropdown-menu.show {
	opacity: 1;
	visibility: visible;
	transition: .4s ease all;
	transform: translateY(0);
}

.project-dropdown-item {
	padding: 1rem 0 0;
}

.search-container input[type=text] {
	padding: 10px 15px;
	font-size: 17px;
	font-weight: lighter;
	border: none;
	width: 100%;
	background-color: #f8f8f8;
	height: 52px;
	color: #333;
	border-bottom: 1px solid#ddd;
}

.search-container input[type=text]:focus {
	border-bottom: 1px solid #08b09c;
	background-color: #EAF4F4;
}

.search-container button {
	float: right;
	padding: 6px 10px;
	margin-top: 8px;
	margin-right: 16px;
	background: #ddd;
	font-size: 17px;
	border: none;
	cursor: pointer;
}

.item-list-badge {
	font-size: 15px;
	padding-bottom: 10px;
	margin: 0;
	border-bottom: 1px solid #eee;
	color: var(--theme-primary-color);
}

.content-item-box hr {
	margin: 10px 0;
}

.dropdown-content-item {
	padding: 0 1rem;
	margin: 0;
}

.project-list-items li {
	display: block;
	margin: 0;
}

.project-list-items li:last-child a {
	border: 0;
}

.selected-dashboard-project li .project-list-items li:after {
	content: unset;
}

.project-list-items li a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	font-size: 16px;
	color: #222;
	font-weight: 200;
	border-bottom: 1px solid#eee;
}

.project-list-items li a:hover {
	color: var(--theme-primary-color);
	font-weight: normal;
}

.project-list-items li a .fa.fa-trash-o:hover {
	color: red;
}

.project-icon {
	width: 20px;
	text-align: center;
	font-size: 14px;
	margin-right: 5px;
}

.navlink-icon {
	margin-left: 5px;
	font-size: 14px;
	vertical-align: middle;
	height: 18px;
}

.dropdown-footer-item {
	padding: 0 1rem;
	border-top: 1px solid #ddd;
	background-color: var(--theme-light-color);
	border-radius: 0 0 8px 8px;
}

.dropdown-footer-item .project-list-items li a {
	color: var(--theme-primary-color);
	padding: 10px 0;
}

.dropdown-overlay-content.open {
	display: block;
}

.fa-file-text.doc-icon {
	font-size: 18px;
	margin: 0 8px;
	color: var(--theme-primary-color);
}

.info-navbar-menu-item {
	display: block;
	font-size: 1rem;
}

.info-navbar-menu-item .info-navbar-menu-icon {
	vertical-align: middle;
	display: inline-block;
}

.info-navbar-menu-item.create-ticket-btn .__react_component_tooltip {
	padding: 0px 12px;
	display: none;
}

.project-input-box {
	min-height: 100px;
}

.project-list-items .no-data-found-wrapper {
	padding: 10px;
	border: 0;
}

.project-list-items .no-data-found-img {
	margin: 0;
}

.project-list-items .no-data-found-content h2 {
	font-size: 1rem;
}

/* profile menu */
.user-auth-img {
	width: 36px;
	background-color: #E5F2FF;
	border-radius: 3px;
	margin-right: 10px;
}

.user-auth-name {
	vertical-align: middle;
	color: #222;
	text-transform: capitalize;
	font-weight: 600;
}

.user-image-outer .arrow-down {
	margin-left: 2px;
	font-size: 20px;
	vertical-align: middle;
	color: #757575;
	text-align: center;
	width: 20px;
}

.user-image-outer {
	cursor: pointer;
	background-color: transparent;
	min-height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
}

.logout-link {
	width: 100%;
	display: block;
	font-size: 14px;
	font-weight: 600;
	padding: 6px 0;
	text-align: left;
	background: transparent;
	border: none;
	outline: none;
	text-decoration: none;
}

.logout-link:hover {
	color: var(--theme-secondary-color);
	background-color: #eee;
}

.logout-link .fa.fa-sign-out {
	margin: 0 10px 0 5px;
}

.user-image-outer:hover {
	background-color: var(--theme-lighter-color);
}

.user-image-outer:hover span.user-auth-name {
	color: var(--theme-primary-color);
}

.brand-name-text {
	padding: 0 30px;
	position: relative;
}

.brand-name-text h3 {
	font-size: 16px;
	margin: 0;
}

.profile-dropdown-menu {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 50px;
	right: 10px;
	padding: 10px;
	z-index: 99;
	min-width: 200px;
	max-width: 200px;
	background-color: #fdfdfd;
	border-radius: 7px;
	-webkit-box-shadow: 2px 0px 12px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 2px 0px 12px 0 rgba(0, 0, 0, 0.12);
	border: 1px solid #eee;
	transition: .4s ease all;
	transform: translateY(-30px);
}

.profile-dropdown-menu.show {
	opacity: 1;
	visibility: visible;
	transition: .4s ease all;
	transform: translateY(0);
}

.profile-dropdown-menu.show::after {
	position: absolute;
	background-color: transparent;
	content: "";
	top: -15px;
	right: 20px;
	/* height: 13px; */
	width: 20px;
	border-bottom: 15px solid #fff;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
}

.dropdown-header-box {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	border-bottom: 1px solid #eee;
	padding-bottom: 10px;
}

.dropdown-header-box figure img {
	max-width: 42px;
}

.dropdown-header-box .text-content {
	padding: 0 8px;
}

.dropdown-header-box p {
	margin: 0;
	font-size: 16px;
}

.dropdown-header-box span {
	color: #757575;
}

.dropdown-content-box ul li {
	display: block;
	margin: 0;
}


.dropdown-content-box ul li a {
	padding: 5px 10px;
	display: block;
	color: #595959;
}
.dropdown-content-box ul li a:hover {
	color: #aa15ba;
}

.dropdown-footer-box {
	padding: 8px 0 0;
	border-top: 1px solid #eee;
	margin-top: 10px;
	font-size: 17px;
}

.dropdown-footer-box a {
	color: #757575;
	font-size: 16px;
}

.dropdown-footer-box a:hover {
	color: var(--theme-secondary-color);
}

.workflow-run-loader-wrapper.full-page-laoder {
	z-index: 99999;
}

@media screen and (max-width:1024px) {
	.info-navigation-menu li.select-header-project {
		display: none;
	}

	.header-section.header-fixed {
		padding-left: 60px;
	}

	.header-section.header-fixed.closed {
		padding-left: 230px;
	}

	.header-project {
		background-color: var(--theme-light-color);
		padding: 7px 10px;
		font-size: 1rem;
	}
}

@media screen and (max-width:640px) {
	.info-navbar-menu li {
		margin: 0 5px;
	}


	.header-section.header-fixed {
		padding-left: 0;
	}

	.header-section.header-fixed.closed {
		padding-left: 0;
	}

	.info-navbar-menu-item.create-ticket-btn {
		min-height: 35px;
		line-height: 32px;
		min-width: auto;
		margin: 0;
	}

	.info-navbar-menu-item.create-ticket-btn .__react_component_tooltip {
		display: block;
	}

	.icon-plus.info-navbar-menu-icon {
		margin: 0;
	}

	.profile-img {
		width: 33px;
		height: 33px;
		font-size: 1.2rem;
		line-height: 30px;
	}

}

@media(max-width:767px) {
	.drawer-toggle {
		position: unset;
		background-color:
			transparent;
		box-shadow: none;
		margin-right: 10px;
		height: auto;
		width: auto;
		font-size: 21px;
	}

	.drawer-toggle:hover {
		background-color: transparent;
		color: var(--theme-primary-color);
	}

	.fa.sm-block {
		display: block;
	}

	.fa.sm-none {
		display: none;
	}

	.user-auth-name {
		display: none;
	}
}
.dropdown-content-box ul li .active:active, .dropdown-content-box ul li .active:focus{
	color: #aa15ba;
}