textarea.form-control {
	min-height: 90px;
	font-size: 1rem;
	padding: 8px 12px;
	line-height: 1.5;
}

.form-group {
	display: block;
	width: 100%;
	margin: 0 0 12px;
	position: relative;
}

.form-group .form-ctrl, .form-group .form-control {
	width: 100%;
    min-height: 42px;
    border: 1px solid #b9bec6;
    border-radius: 4px;
    padding: 6px 12px;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: bolder;
    outline: none;
    font-family: inherit;
}


.form-group.telephone-outer .react-tel-input input[type="tel"],
.form-group.telephone-outer .react-tel-input input[type="text"] {
	width: 100%;
	min-height: 42px;
	border: 1px solid #b9bec6;
	border-radius: 4px;
	padding: 6px 12px 6px 46px;
	-webkit-transition: 0.4s ease all;
	transition: 0.4s ease all;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-weight: normal;
	outline: none;
	font-family: inherit;
}

.form-group .form-ctrl.input-large,
.form-group .form-control.input-large {
	min-height: 50px;
}

.form-group .form-ctrl:focus, .form-group .form-ctrl:active,
.form-group .form-control:focus, .form-group .form-control:active {
	background: var(--theme-lighter-color);
	border-color: #db772c;
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-group.telephone-outer .react-tel-input input[type="tel"]:focus,
.form-group.telephone-outer .react-tel-input input[type="tel"]:active {
	background: var(--theme-lighter-color);
	border-color: var(--theme-primary-color);
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-group .react-tel-input .selected-flag {
	z-index: 1;
	position: relative;
	width: 40px;
	min-height: 35px;
	padding: 0 0 0 8px;
	border-radius: 3px 0 0 3px;
	border-left: 0;
	border-right: 1px solid #cacaca;
	box-shadow: none;
	border-top: 0;
	background: #d0e0f2;
	outline: none;
	border-bottom: none;
}

.form-group .react-tel-input {
	width: 100%;
}

form .form-group label {
	display: inline-block;
	margin: 0 2px 3px;
	font-weight: 500;
	font-size: 1rem;
}

form .form-group label .req {
	color: #c50e29;
}

.required{
	color: #c50e29 !important;
    font-weight: 600 !important;
}
.select-wrapper {
	position: relative;
}

.select-wrapper::after {
	position: absolute;
	content: "";
	right: 16px;
	height: 6px;
	border-right: 7px solid transparent;
	top: 18px;
	border-left: 7px solid transparent;
	border-top: 7px solid rgba(3, 27, 78, .4);
	z-index: 99;
	pointer-events: none;
}

.select-wrapper select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	position: relative;
	z-index: 9;
	padding: 5px 10px;
	color: #333333;
	line-height: 2;
	background: #EBEEF3;
    background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 8px;
    background-size: 25px;
	cursor: pointer;
}

.form-group textarea.form-control {
	min-height: 90px;
	resize: vertical;
}

.help-block.with-errors {
	position: relative;
	color: #d30c16;
	/* #f2545b */
	left: 2px;
	font-size: 13px;
	display: none;
	transition: 0.4s ease all;
}

.react-tel-input .flag-dropdown {
	outline: none;
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	background-color: #f5f5f5;
	border: 1px solid #b9bec6;
	border-radius: 4px 0 0 4px;
}

.has-error .help-block.with-errors {
	display: block;
}

.form-group.has-error .form-control {
	border-color: #f2545b;
}

.input-wrapper.has-error .react-tel-input input[type="tel"], .input-wrapper.has-error .react-tel-input .flag-dropdown {
	border-color: #f2545b;
}

.form-group .form-ctrl:valid~.help-block.with-errors,
.form-group .form-control:valid~.help-block.with-errors {
	display: none;
}

.form-group.has-error .form-ctrl:valid~.help-block.with-errors,
.form-group.has-error .form-control:valid~.help-block.with-errors {
	display: block;
}

.form-group.has-error .form-ctrl.file:valid~.help-block.with-errors,
.form-group.has-error .form-control.file:valid~.help-block.with-errors {
	display: block;
}

.has-error .form-ctrl, .has-error .form-control {
	border-color: #f2545b;
}

p.error-msg {
	color: #f2545b;
	margin: 5px 0;
}

.api-error-msg {
	color: #dc3940;
	opacity: 0;
	transition: 0.5s ease-in-out;
	visibility: hidden;
}

.api-error-msg.error {
	opacity: 1;
	visibility: visible;
}

.form-group .form-ctrl:valid~.help-block,
.form-group .form-control:valid~.help-block {
	display: none;
}

.form-group.btn-group i.fa-spin {
	font-size: 2rem;
	margin-left: 1rem;
	color: #0052CE;
}

.btn.btn-with-loader {
	position: relative;
}

.loader-container {
	vertical-align: middle;
	display: inline-block;
	margin-left: 10px;
	line-height: 1;
}

.btn-loader-wrapper {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(241, 246, 255, 0.77);
}

.btn.box-shadow:hover {
	box-shadow: 3px 3px 3px rgba(98, 80, 89, 0.3);
}

/*radio contol*/
/*.radio-group {*/
/*margin-bottom: 0px;*/
/*}*/
.radio-group .radio-ctrl {
	display: inline-block;
	width: 20px;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
}

.form-group .radio-group label {
	display: inline-block;
	vertical-align: text-top;
	margin-right: 1rem;
	margin-bottom: 0;
	font-size: 14pt;
	cursor: pointer;
}

.form-group .radio-group label i {
	margin-left: 1rem;
	width: 20px;
	text-align: center;
	color: #0052CE;
}

/* dialog box btn*/
.mdc-dialog .mdc-dialog__surface {
	width: 500px;
	max-width: 100%;
}

.mdc-dialog__footer .mdc-button.mdc-dialog__footer__button {
	background-color: #2E7EED;
	color: #fff;
}

.mdc-dialog__footer .mdc-button.mdc-dialog__footer__button::after, .mdc-dialog__footer .mdc-button.mdc-dialog__footer__button::before {
	content: unset;
}

.mdc-dialog__footer .mdc-button.mdc-dialog__footer__button:hover, .mdc-dialog__footer .mdc-button.mdc-dialog__footer__button:focus {
	background-color: #0052CE;
	color: #fff;
}

.mdc-dialog__footer .mdc-button.mdc-dialog__footer__button.mdc-dialog__footer__button--cancel {
	background: transparent;
	color: #2E7EED;
	border: 1px solid #2E7EED;
}

.mdc-dialog__footer .mdc-button.mdc-dialog__footer__button.mdc-dialog__footer__button--cancel:hover, .mdc-dialog__footer .mdc-button.mdc-dialog__footer__button.mdc-dialog__footer__button--cancel:focus {
	background: transparent;
	color: #0052CE;
	border-color: #0052CE;
}

/*  form sm ctrl */
.form-sm-ctrl .form-group {
	margin: 0 0 8px;
}

.form-sm-ctrl .form-group .form-ctrl,
.form-sm-ctrl .form-group .form-control {
	min-height: 36px;
	padding: 4px 10px;
}

.form-sm-ctrl .form-group .css-1hwfws3 {
	min-height: 36px;
	padding: 2px 10px;
	font-weight: lighter;
}

.form-sm-ctrl .form-group label {
	margin: 0 1px 3px;
	font-size: 14px;
}

/* react select */
.react-select-wrapper .css-yk16xz-control, .react-select-wrapper .css-1pahdxg-control {
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsl(0, 0%, 100%);
	border-color: #b9bec6;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	cursor: default;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: 42px;
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
}

.form-group .react-select-wrapper .css-1hwfws3 {
	padding: 3px 12px;
	font-weight: lighter;
}

.form-group .react-select-wrapper .css-1pahdxg-control {
	background: var(--theme-lighter-color);
	box-shadow: none;
	border: 1px solid var(--theme-primary-color);
}

.form-group .react-select-wrapper .css-26l3qy-menu {
	font-weight: lighter;
}

.css-1wa3eu0-placeholder {
	color: hsl(0, 0%, 50%);
	margin-left: 2px;
	margin-right: 2px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	box-sizing: border-box;
	font-weight: normal;
}

.form-group [type='radio'].radio~.radiomark {
	width: 22px;
	height: 22px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #969696;
	cursor: pointer;
	margin-right: 10px;
	border-radius: 50%;
}

.form-group .radiomark:after {
	top: 4px;
	left: 4px;
	width: 12px;
	height: 12px;
	content: "";
	position: absolute;
	display: none;
	border-radius: 50%;
	background: var(--theme-primary-color);
}

.form-group input[type="file"].form-control {
	line-height: 1.5;
	padding: 9px 12px;
	cursor: pointer;
}

input.helplinks {
    margin-bottom: 1rem;
}