.news-feeds_item__content h3 {
    margin: 0 0 10px;
    font-size: 16px;
}
.news-feeds_item__content {
    background-color: #fff;
    width: 100%;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #ddd;
}
.news-feeds_item {
    margin-bottom: 1rem;
}
.news-feeds_item__content p {
    margin: 0;
    font-size: 13px;
}
.dashboard-overview__card__header h2 {
    margin: 0 0 1rem;
    font-size: 24px;
    font-weight: 500;
}