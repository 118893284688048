/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Rubik:300,400,700,900');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */
:root body {
    --theme-primary-color: #bf1f67;
    --theme-secondary-color: #db1162;
    --theme-light-color: #ea6ca5;
    --theme-lighter-color: #fff3f8;
    --theme-color: #bf1f67;
    --theme-heading-color: #2d476d;
    --theme-text-color: #4c5d75;
    --theme-primary-color2: #f99316;
    --theme-secondary-color2: #f2a551;
    --default-font-size: 1rem;
    --theme-primary-font-family: 'Roboto', 'NonBreakingSpaceOverride', Helvetica,
        Arial, sans-serif;
    --theme-secondary-font-family: 'Roboto', 'NonBreakingSpaceOverride',
        Helvetica, Arial, sans-serif;
}

:root input {
    --theme-border-color: #ccc;
    --default-font-family: inherit;
    --default-border-width: 2px;
    --default-icon-size: 42px;
}

.btn {
    --default-font-size: 1.2rem;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 14px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    font-family: var(--theme-primary-font-family);
    font-size: 14px;
    line-height: 1.5;
    color: #222222;
    background: #ffffff;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--theme-secondary-font-family);
    margin: 0 0 2rem;
}

h1 {
    font-size: 2rem;
    margin: 0.67em 0;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.3rem;
}

h4 {
    font-size: 1.2rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.9rem;
}

p {
    margin: 0 0 1rem;
    font-family: var(--theme-primary-font-family);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    background-color: transparent;
    color: var(--theme-primary-color2);
    text-decoration: none;
}

a:active,
a:focus,
a:hover {
    outline: 0;
    text-decoration: none;
    color: var(--theme-primary-color);
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
    vertical-align: middle;
    max-width: 100%;
}

svg:not(:root) {
    overflow: hidden;
}

hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input[type='search'] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    display: block;
}

a:focus {
    outline: none;
    outline-offset: -2px;
    text-decoration: none;
}

figure {
    margin: 0;
}