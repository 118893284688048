ul.breadcrumb {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}
li.breadcrumb-item {
    display: flex;
    align-content: center;
    align-items: center;
}
ul.breadcrumb li:last-child span {
    color: #1e1e1e;
    font-weight: bold;
}
.breadcrumb-item .seperator {
    color: #999999;
    margin: 0 4px;
    font-size: 12px;
    vertical-align: middle;
    line-height: 1;
    display: inline-block;
}
.page-navigation-breadcrumbs {
    padding: 0 0 1rem 0;
}