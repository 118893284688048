/* New CSS */
.header-section.header-fixed {
    padding: inherit !important;
}

.brand-logo-wrapper figure {
    justify-content: flex-start !important;
}

.brand-logo {
    margin: inherit !important;
}

.brand-logo-wrapper {
    border-right: none !important;
}

.header-section.header-fixed.header-fixed-admin {
    padding: 0 !important;
}

.profile-fields_data.education-document-list {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

span.anticon.anticon-edit {
    display: flex;
    align-items: center;
    top: 0px !important;
    
}

.col-12.col-sm-3.col-md-1.col-lg-1.profile-edit {
    display: flex;
}

.module-start_date strong {
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #1e1e1e;
}

button.btn.btn-compact.box-shadow.module-completed {
    opacity: 1;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
}

button.btn.btn-compact.box-shadow.module-completed:hover {
    background: #c0ddd5;
    color: #165556;
}

.module-list-refrence_link_list ul {
    padding-bottom: 1rem;
}

.module-list-header h3 {
    margin: 1.2rem 0 1rem 0;
}

figure.hamburger-logo {
    display: none !important;
}

.dialog-content {
    padding: 0 25px;
    color: #46536b;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: initial;
}

.dialog-footer {
    gap: 2rem;
}

.dialog-footer button {
    margin-right: 0 !important;
}

.form-group.candidate-search {
    padding: 0;
    width: 100%;
    margin-bottom: 1rem;
}
button.candidate-search-functionality {
    position: absolute;
    right: 5px;
    top: 2px;
    height: 35px;
    width: 35px;
    border: 0;
    outline: 0;
    background: transparent;
}

.module-start_date img {
    width: 25px;
    margin-right: 1rem;
}

button.btn.btn-compact.btn-outline:hover, a.btn.btn-compact.btn-outline:hover {
    color: #48194e;
    background: #f3eaff;
}

.dashboard-employee-body-content .header-section.header-fixed .brand-logo-wrapper {
    background: #FFF;
}

img.brand-logo {
    display: block;
}

img.brand-logo-mobile {
    display: none;
    width: 150px;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    background-color: #aa15ba !important;
    border-color: #aa15ba !important;
    cursor: default;
}

img.edit-module {
    width: 40px;
}

/* Container Changes */
main.main-content-wrapper .container {
    margin: 0;
}

.pagination-container {
    margin: 0 !important;
}

.dashboard-wrappper.user-side-dashboard .container {
    margin: 0 auto;
}

.react-tabs-section-wrapper {
    padding-top: 2rem;
}

.user-profile-container .container {
    padding: 0;
}

a.header-menu_link.active {
    color: #aa15ba;
}

article.task-list_item_wrapper.task-list_item_wrapper-active {
    box-shadow: 1px 2px 3px 1px rgb(106 85 95 / 24%);
    border-color: #aa15ba;
}

.xd-table-btn.delete:hover {
    outline: none;
    color: #aa15ba!important;
    border-color: #aa15ba!important;
    background-color: #fff!important;
}
button.custom-icon-button:hover, button.custom-icon-button:focus, button.custom-icon-button:active {
    outline: none;
    color: #aa15ba!important;
    border-color: #aa15ba!important;
    background-color: #fff!important;
}
.xd-table-btn.delete {
    color: #48194e !important;
    background-color: #f8f3f3;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

header.header-section.header-fixed.full-page-header .brand-logo-wrapper {
    background: #fff;
}

header.header-section.header-fixed.full-page-header .brand-logo-wrapper img.brand-logo {
    margin: 0 !important;
    height: 100%;
}

.btn:focus, .btn:hover {
    color: #48194e !important;
    background: #f3eaff !important;
    outline: none;
    box-shadow: none;
}
.tooltipHeight{
    line-height: 10px;
}
.table-action-group .xd-table-btn {
    font-size: 16px!important;
    min-height: 40px!important;
    width: 40px;
    border-radius: 50%!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}
.user-profile-container .table-container {
    margin: 0;
}
.table-action-group {
    display: flex;
}
.col-12.col-sm-3.col-md-1.col-lg-3.button-allignment {
    position: absolute;
    right: -40px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    top: 32px;
}
.row.help-links-allignment {
    display: block;
}
.col-12.col-sm-3.col-md-1.col-lg-3.cross-button-allignment {
    position: absolute;
    right: -40px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0;
    top: 4px;
}
.form-group.signup-input-box {
    margin-top: 0;
    padding: 0;
}
.invite-employee-form-item .cross-icon {
    top: 15px;
}
.row.help-links-allignment .form-group {
    margin: 0;
    padding: 5px 0;
}
img.onboarding-search-mobile {
    display: none;
}
/* Container Changes */
@media(max-width:767px) {
    .navigation-bar {
        display: block !important;
        border-bottom: none !important;

    }
    button.candidate-search-functionality img:first-child {
        display: none;
    }
    img.onboarding-search-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    button.candidate-search-functionality {
        position: relative;
        border: 1px solid #48194e;
        right: 0;
        top: 10px;
        background: #481943;
        border-radius: 4px;
    }
    .invite-employee-form-item .form-group.button-allignment-mobile-view button {
        width: 215px;
    }
    .invite-employee-form-item .xd-btn-group
    {
        display: flex!important;
        width: 80px;
    }
    .invite-employee-form-item .form-group.button-allignment-mobile-view {
        margin: 0;
    }
    
    .dashboard-employee-body-content .header-section.header-fixed img.brand-logo {
        display: block;
    }

    .content-section-wrapper {
        position: relative;
        overflow: auto;
        overflow-x: scroll;
    }

    .dashboard-content-body {
        margin: 0 !important;
        padding-top: 10rem;
    }

    .dashboard-content-body.closed {
        padding-top: 0rem;
        margin-left: 190px !important;
    }
    aside.drawer-wrapper.closed.drawer-wrapper-closed {
        width: 0;
    }
    
    .dashboard-content-body.closed.dashboard-content-body-closed {
        margin-left: 0!important;
    }
    .header-section.header-fixed.closed .brand-logo-wrapper {
        /* width: 70% !important; */
        justify-content: space-between !important;
        align-items: center !important;
    }

    .header-section.header-fixed.closed div#drawer-toggle {
        border: none;
    }

    .header-section.header-fixed.closed .brand-logo-wrapper figure.brand-logo-md {
        display: block !important;
    }

    .header-section.closed .brand-logo-wrapper figure.brand-logo-xs {
        display: none !important;
    }

    .header-section.header-fixed.closed .brand-name-text, .header-section.header-fixed.header-fixed-admin .brand-name-text {
        padding: 0 !important;
    }

    .brand-logo-wrapper {
        width: 100% !important;
        display: flex !important;
        justify-content: flex-start !important;
        border-right: none !important;
        padding-top: 1rem;
    }

    .header-section.header-fixed.header-fixed-admin .navigation-menu-bar {
        display: flex !important;
        padding: 10px 0 0 5px;
    }

    .header-section.header-fixed.closed .navigation-menu-bar {
        display: flex !important;
        /* width: 70% !important; */
    }

    .header-section.header-fixed.closed {
        padding: 0 !important;
    }

    .navigation-menu-bar {
        width: 100% !important;
        display: block !important;
    }

    .main-content-wrapper.employee-dashboard_wrapper {
        padding: 80px 0 !important;
    }

    .module-list_grid {
        display: flex !important;
        flex-direction: column !important;
    }

    .module-list_item_content {
        display: block !important;
    }
    .module-list_item_content_disabled {
        display: block !important;
    }
    .module-progres_content {
        justify-content: flex-start !important;
        padding-top: 15px !important;
    }

    ul.module_list_footer-content {
        flex-direction: column !important;
        gap: 1rem !important;
    }

    .module-list_item_content p {
        word-break: break-all !important;
    }

    .presentation-overlay {
        width: auto !important;
    }

    ul.meta-data_list {
        flex-direction: column !important;
        gap: 1rem !important;
    }

    header.header-section.header-fixed.header-fixed-admin .brand-logo-wrapper {
        display: flex !important;
        align-items: center;
        justify-content: space-between !important;
    }

    div#drawer-toggle {
        box-shadow: none;
        border: none;
        color: #1e1e1e;
    }

    .col-12.col-sm-3.col-md-1.col-lg-1.profile-edit {
        display: flex;
        padding-top: 1rem;
    }

    .dashboard-employee-body-content header.header-section.header-fixed .brand-logo-wrapper {
        justify-content: space-between !important;
    }

    .dashboard-employee-body-content header.header-section.header-fixed ul.info-navbar-menu {
        display: none;
    }

    .dashboard-employee-body-content header.header-section.header-fixed ul.info-navbar-menu.info-navbar-menu-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 1rem 0;
        justify-content: center;
        text-align: center;
    }

    .dashboard-employee-body-content header.header-section.header-fixed ul.info-navbar-menu.info-navbar-menu-block li {
        border-bottom: 1px solid gainsboro;
        width: 100%;
        text-align: initial;
        line-height: 3rem;
    }

    .dashboard-employee-body-content header.header-section.header-fixed ul.info-navbar-menu.info-navbar-menu-block li:last-child {
        border: none;
        width: auto;
    }

    figure.hamburger-logo {
        display: flex !important;
    }

    nav.navigation-bar.navigation-bar-height {
        height: 1200px;
    }

    .dashboard-employee-body-content main.main-content-wrapper.employee-dashboard_wrapper .task-list-action {
        justify-content: flex-start;
        margin-top: 1rem;
    }

    section.full-width-content-section.task-listing-section .container {
        padding: 0 15px;
    }

    .dashboard-employee-body-content main.main-content-wrapper.employee-dashboard_wrapper .profile-fields_data.education-document-list {
        flex-direction: column;
    }

    .listing-page-btn-grp.xd-btn-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .form-group.candidate-search {
        padding: 1rem 0;
    }

    .dashboard-body-wrapper {
        display: block;
        padding: 20px 0;
        overflow: scroll;
    }

    .email, .task, .scoring, .avatar, .actions {
        word-break: normal !important;
    }

    .dashboard-content-header-right {
        text-align: left !important;
        padding-top: 1rem;
    }

    img.brand-logo {
        display: none;
    }

    img.brand-logo-mobile {
        display: block;
        width: 150px;
    }

    .profile-dropdown-menu {
        top: 81px !important;
        right: 55px !important;
    }

    .header-section.header-fixed.header-fixed-admin .profile-dropdown-menu {
        top: 50px !important;
        right: 10px !important;
    }

}

@media(min-width:768px) and (max-width:1024px) {

    .brand-logo-wrapper {
        border-right: none !important;
    }
    
    .navigation-bar {
        padding-top: 1rem !important;
        border-bottom: none !important;
    }

    .main-content-wrapper.employee-dashboard_wrapper {
        padding: 80px 0 !important;
    }

    .module-list_grid {
        display: flex !important;
        flex-direction: column !important;
    }

    .module-progres_content {
        justify-content: flex-start !important;
        padding-top: 15px !important;
    }

    ul.module_list_footer-content {
        flex-direction: column !important;
        gap: 1rem !important;
    }

    .module-list_item_content p {
        word-break: break-all !important;
    }

    .brand-logo {
        margin: auto !important;
    }

    .brand-logo-wrapper figure {
        padding: 0 !important;
    }

    .header-section.header-fixed.closed {
        padding: 0 !important;
    }

    .header-section.header-fixed.closed .brand-logo-wrapper figure.brand-logo-md {
        display: block !important;
    }

    .header-section.closed .brand-logo-wrapper figure.brand-logo-xs {
        display: none !important;
    }

    .header-section.header-fixed.closed .brand-logo-wrapper {
        width: 230px !important;
    }

    .header-section.header-fixed.header-fixed-admin .brand-logo-wrapper {
        width: 60px !important;
    }

    .header-section.header-fixed.header-fixed-admin figure.brand-logo-md {
        display: none !important;
    }

    .header-section.header-fixed.header-fixed-admin figure.brand-logo-xs {
        display: block !important;
        width: 50px !important;
    }

    .header-section.header-fixed.header-fixed-admin .navigation-bar {
        justify-content: normal;
    }

    .header-section.header-fixed.header-fixed-admin .navigation-bar, .header-section.header-fixed.closed .navigation-bar {
        padding-top: 0 !important;
    }

    .dashboard-employee-body-content header.header-section.header-fixed .brand-logo {
        margin: 0 !important;
        width: 150px !important;
    }

    ul.info-navbar-menu {
        display: flex;
        align-items: center;
    }

    .dashboard-employee-body-content header.header-section.header-fixed .brand-logo-wrapper {
        width: 140px !important;
    }

    .dashboard-employee-body-content header.header-section.header-fixed .navigation-menu-bar {
        width: calc(100% - 170px);
    }

    .dashboard-content-body {
        margin-left: 60px;
    }

    .form-group.candidate-search {
        padding-top: 1rem;
    }
}

@media(min-width:768px) {
    .header-section.header-fixed.closed .container, .header-section.header-fixed.header-fixed-admin .container {
        padding: 0 !important;
        max-width: 100% !important
    }
    .form-group.button-allignment-mobile-view button {
        width: 235px;
    }
}

.header-section.header-fixed.closed {
    padding: 0 !important;
}
.xd-modal-dialog.box.animated.fadeInDown {
    background: transparent;
    border: none;
    overflow: hidden;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.xd-modal.animated.show.fadeIn {
    background: #0002;
}

.xd-modal-dialog.box.animated.fadeInDown .popup-box .box {
    left: 0;
    margin: 0;
}
.deleteIntern-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(min-width:280px) and (max-width:319px)
{
    .form-group.candidate-search .form-control {
        background-size: 14px;
    }
    .ver-responsive-table .xd-table-td td:after {
        padding: 8px 0px!important;
    }
    .module-start_date strong {
        font-size: 1rem;
    }
    .module-start_date span {
        font-size: 1rem;
    }
    .module-start_date
    {
        display: block!important;
    }
    .module-start_date img
    {
        margin-right: 5px;
    }
    .module-start_date span
    {
        margin-left: 5px!important;
    }
}