/*! CSS Used from: Embedded */
.row::after, .row::before {
    display: inline-block;
}

/*! CSS Used from: Embedded */
*, ::after, ::before {
    box-sizing: inherit;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}


@media (min-width:641px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width:1025px) {
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1369px) {
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*! CSS Used from: Embedded */
* {
    box-sizing: border-box;
}

*:before, *:after {
    box-sizing: border-box;
}

img {
    vertical-align: middle;
    max-width: 100%;
    border: 0;
}

/*! CSS Used from: Embedded */
.dashboard-card {
    padding: 15px;
    background-color: #fff;
    margin-bottom: 3rem;
    box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 2px 8px 0 rgba(167, 175, 183, 0.33);
}

.info-box {
    display: flex;
    min-height: 75px;
    background: #fff;
    width: 100%;
    border-radius: 2px;
}

.info-box-content {
    padding: 5px 10px;
}
.info-box-text {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

.info-box-number {
    display: block;
    font-size: 30px;
}

.info-box img {
    width: 75px;
    height: 75px;
}

@media (min-width:1024px) and (max-width:1339px) {
    .info-box-text {
        width: 85%;
    }
}

/*! CSS Used from: Embedded */
::-webkit-input-placeholder {
    color: #8792A1 !important;
}

::placeholder {
    color: #8792A1 !important;
}

/*! CSS Used from: Embedded */
*, *::before, *::after {
    box-sizing: inherit;
}
@media (min-width: 768px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1024px) {
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1440px) {
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*! CSS Used from: Embedded */
@media (min-width: 768px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1024px) {
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1440px) {
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*! CSS Used from: Embedded */
/*! CSS Used from: Embedded */
::-moz-selection {
    color: #fff;
    background: #1890ff;
}

::selection {
    color: #fff;
    background: #1890ff;
}

/*! CSS Used from: Embedded */

@media print {
    *, *:before, *:after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        box-shadow: none !important;
    }

    img {
        page-break-inside: avoid;
        max-width: 100% !important;
    }
}
.row:after {
    clear: both;
}

a.pull-left.inline-block.mr-15.txt-light.collapsed img {
    width: 20px;
    position: absolute;
    left: 7px;
    top: 13px;
    display: block;
}

a.pull-left.inline-block.mr-15.txt-light img {
    width: 20px;
    position: absolute;
    left: 7px;
    top: 13px;
}

a.table-inside-btn.right.collapsed {
    border-radius: 5px;
    width: auto;
    min-width: 100px;
    border: none;
    display: inline-block;
    min-height: 42px;
    line-height: 42px;
    font-size: 13pt;
    color: #fff;
    text-align: center;
    background: #48194e;
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    padding: 0 2rem;
}

a.table-inside-btn.right.collapsed:hover {
    color: #48194e;
    background: #f3eaff;
}

.table-responsive a {
    color: #1890ff;
}

.panel-wrapper.collapse.in.panel-wrapper-display {
    opacity: 0;
    height: 0 !important;
}

img.zmdi.zmdi-chevron-down.down-arrow.down-arrow-rotate {
    transform: rotate(180deg);
}
table#myTable1 thead {
    border-bottom: 2px solid #eee;
}
.collapse.in {
    display: block;
    overflow: hidden;
    overflow-y: auto;
}