/*! CSS Used from: https://xenonstack-jobs-dev.neuralcompany.team/app/static/css/2.67f81e70.chunk.css */
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

input[type="password"] {
    -webkit-appearance: none;
}
a:hover {
    color: #40a9ff;
}

a:active {
    color: #096dd9;
}

a,
button,
input:not([type="range"]),
label {
    touch-action: manipulation;
}

[type="submit"],
button {
    -webkit-appearance: button;
}

[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

::-moz-selection {
    color: #fff;
    background: #1890ff;
}

::selection {
    color: #fff;
    background: #1890ff;
}

/*! CSS Used from: https://xenonstack-jobs-dev.neuralcompany.team/app/static/css/main.b5d6ad8a.chunk.css */
.row:after,
.row:before {
    display: inline-block;
}

.form-group .with-error {
    display: none;
}

.wrapper-container {
    padding: 120px 0 80px;
}

.section_header {
    margin-bottom: 40px;
}

label {
    display: block;
    margin: 0 2px 6px;
    font-size: 12pt;
    color: #8a96a8;
    font-family: Roboto, sans-serif;
    min-height: 26px;
}

.form-group label .req {
    color: #f74734;
    margin-left: 4px;
}

.form-group .form-ctrl:active,
.form-group .form-ctrl:focus {
    background: #fff;
    border-color: #48194e;
    outline: none;
    box-shadow: none;
}

.forgot_pw {
    display: block;
    margin: 5px 0;
    color: #48194e;
}

.forgot_pw:hover {
    color: #aa15ba;
}

.btn {
    border-radius: 5px;
    width: auto;
    min-width: 100px;
    border: none;
    display: inline-block;
    min-height: 42px;
    line-height: 42px;
    font-size: 13pt;
    color: #fff;
    text-align: center;
    background: #48194e;
    cursor: pointer;
    transition: all 0.4s ease;
    padding: 0 2rem;
}

.filter-options-right .btn{
    min-width: max-content;
}

.btn,
.btn:focus,
.btn:hover {
    outline: none;
    box-shadow: none;
}

.btn:focus,
.btn:hover {
    color: black;
    background: white;
}

.btn.sign-in {
    width: 100%;
}

.btn.sign-in:focus,
.btn.sign-in:hover {
    color: #48194e;
    background: #f3eaff;
}

form#admin-login-form button {
    width: 100%;
}

.form-link-group a {
    color: #4d5b66;
    font-family: Roboto, sans-serif;
    font-size: 16px;
}

.form-link-group a:hover {
    color: #aa15ba;
}

@media screen and (max-width: 1024px) {
    .big-heading {
        font-size: 27px;
        margin-bottom: 1rem;
    }

    .section_header {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 640px) {
    .big-heading {
        font-size: 24px;
    }

    .login-div {
        border: 1px solid #ddd;
        padding: 20px;
        margin-bottom: 2rem;
    }

    .section_header {
        margin-bottom: 20px;
    }

    .form-group .form-ctrl {
        padding: 6px 10px;
    }
}

h2 {
    font-family: sf_pro_displaybold, sans-serif;
    margin: 0 0 2rem;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
    font-size: 3.6rem;
    color: #1e1e1e;
    line-height: 130%;
}

a:focus,
a:hover {
    text-decoration: none;
    outline: none;
    color: #2e7eed;
}

a:focus {
    outline-offset: -2px;
}

p {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 17px;
    font-family: Roboto, sans-serif;
    color: #4d5b66;
}
input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
button:hover:after {
    width: 100%;
}

.login-div.existing-user {
    border-right: 1px solid #eee;
    border: 0;
}
.wrapper-container.signing-container {
    position: relative;
    padding: 8.8rem 0;
    height: 100vh;
    display: flex;
    align-items: center;
}
.wrapper-container.signing-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
    background-image: url('../../../static/images/sign-in.svg');
}
form#loginForm .form-group label {
    color: #1e1e1e;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

a.forgot_pw {
    color: #4D5B66;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding-top: 1rem;
    margin: 0;
}
a.dont-have-an-account,
a.forgot_pw {
    color: #4d5b66;
    font-family: Roboto, sans-serif;
    font-size: 16px;
}
a.dont-have-an-account:hover {
    color: #aa15ba;
}
.form-group .form-ctrl::-webkit-input-placeholder {
    color: #8792a1;
}
.form-group label {
    color: #1e1e1e;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
.big-heading {
    font-size: 2.5rem;
    color: #48194e;
    margin-bottom: 1rem;
    font-family: Roboto, sans-serif;
    font-weight: 700;
}
.container.user-jounrney-form-container {
    height: 100%;
}
.xenonstack-jobs-copyright i {
    font-size: 1rem;
    color: #4d5b66;
}
.xenonstack-jobs-copyright p {
    font-size: 1rem;
}
::-webkit-input-placeholder {
    color: #8792a1 !important;
}
::placeholder {
    color: #8792a1 !important;
}
.form-group input::-webkit-input-placeholder {
    color: #4d5b66 !important;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}
.form-group input {
    opacity: 1;
    color: #4d5b66 !important;
    background: #fff;
}
.user-journey-form::-webkit-scrollbar {
    display: none;
}
.dashbaord-user-form {
    padding-top: 15px;
    overflow: hidden;
    overflow-y: auto;
}
form#admin-login-form p.error-msg {
    color: #f2545b;
    margin: 5px 0;
    font-size: 1rem;
    text-align: left;
    padding-top: 1rem;
}
.wrapper-container.signing-container .container {
    margin: 0 auto;
}
@media (max-width: 767px) {
    .wrapper-container.signing-container:after {
        display: none;
    }

    .wrapper-container.signing-container .login-div {
        padding: 0;
        border: 0;
        margin: 0;
    }

    .wrapper-container.signing-container {
        align-items: flex-start;
        padding: 3rem 0;
    }

    .signing-container .btn-group {
        display: flex;
        justify-content: space-around;
    }

    .xenonstack-jobs-copyright {
        display: flex;
        align-items: baseline;
        grid-gap: 3px;
        gap: 3px;
    }

    .user-journey-form {
        width: 100% !important;
    }
}

@media (max-width: 1023px) {
    .signing-container .big-heading {
        font-size: 2.4rem;
    }
}

@media (min-width: 768px) and (max-width: 1439px) {
    .section_header {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .wrapper-container {
        padding: 0;
        min-height: 0 !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .wrapper-container {
        padding: 40px 0;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

a {
    color: #48194e;
    text-decoration: none;
    background-color: initial;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
    color: #aa15ba;
}

button {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input {
    line-height: normal;
}

@media print {

    *,
    :after,
    :before {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " ("attr(href) ")";
    }

    img {
        page-break-inside: avoid;
        max-width: 100% !important;
    }

    h2,
    p {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }
}


button,
input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    color: inherit;
    overflow: visible;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    border: 0;
}

.row:after {
    clear: both;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome, sans-serif;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-copyright:before {
    content: "\F1F9";
}



button:focus {
    border: none;
    outline: 0;
}

.wrapper-container.signing-container, .wrapper-container.verify-email-container, .wrapper-container.verification-container, .wrapper-container.signing-container.personal-information, .wrapper-container.signing-container.education, .wrapper-container.signing-container.experience, .wrapper-container.signing-container.basic-information, .wrapper-container.signing-container.create-password {
    position: relative;
    padding: 8.8rem 0;
    height: 100vh;
    display: flex;
    align-items: center;
}

.wrapper-container.verify-email-container::after {
    background-image: url('../../../static/images/verify-your-email.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container::after {
    background-image: url('../../../static/images/sign-in.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.sign-up::after {
    background-image: url('../../../static/images/sign-up.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.verification-container::after {
    background-image: url('../../../static/images/email-verification.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.personal-information::after {
    background-image: url('../../../static/images/personal-information.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.education::after {
    background-image: url('../../../static/images/education.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.experience::after {
    background-image: url('../../../static/images/experience.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.basic-information::after {
    background-image: url('../../../static/images/basic-information.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.create-password::after {
    background-image: url('../../../static/images/create-password.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.user-journey-form.user-journey-form-internal-steps {
    width: 60%;
    overflow: hidden;
    overflow-y: scroll;
}

a.dont-have-an-account {
    color: #4D5B66;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding-top: 10px;
}

.form-group .form-ctrl::placeholder {
    color: #8792A1;
}

.form-group {
    margin: 0 0 20px;
    display: block;
    padding: 1rem 0;
    position: relative;
}

.btn.mar-t-2 {
    margin-top: 3rem;
    border-radius: 5px;
}

.subtitle {
    width: 55%;
    color: #8792A1;
    font-weight: 400;
    font-size: 2.2rem;
    font-family: 'Roboto', sans-serif;
}

.subtitle.big-subtitle {
    width: 80%;
    color: #4D5B66;
    padding-block-start: 1.2rem;
}

.user-journey-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 50%;
}
.xenonstack-jobs-copyright {
    display: flex;
    align-items: baseline;
    grid-gap: 10px;
    gap: 10px;
    padding-top: 3rem;
}

.col-12.col-sm-12.col-md-12.right {
    display: flex;
}

.col-12.col-sm-12.col-md-12.right button {
    margin: 0;
    padding: 0;
    width: 20rem;
}

.col-12.col-sm-12.col-md-12.right .btn-group {
    width: 100%;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 1.2rem;
}

button.btn.signin.mar-t-2.btn-2 {
    background: #fff;
    border: 1px solid #48194e;
    color: #48194e;
}

.form-group .form-ctrl {
    width: 100%;
    min-height: 46px;
    padding: 6px 16px;
    font-size: 12pt;
    border: 1px solid #d5dce3;
    border-radius: 5px;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8792A1 !important
}

.form-group input::placeholder {
    color: #4d5b66 !important;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}
.telephone-outer .react-tel-input input[type="tel"], .select-wrapper select.form-ctrl, .css-151xaom-placeholder {
    color: #4d5b66 !important;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.row.add-your-social-links-row {
    padding-top: 3rem;
}
.css-1o9y6at-multiValue {
    border: 1px solid #48194e;
    background: #f3eaff;
    border-radius: 5px !important;
    color: #48194e;
}
.css-1pcexqc-container {
    border: 1px solid #D5DCE3;
    border-radius: 5px
}
.css-aesaq-control {
    border: 0;
}
.css-aesaq-control.xenon__control {
    border: 0;
    background: 0;
    border-color: transparent;
}

.form-group .css-1hwfws3 {
    font-weight: normal !important;
    font-size: 1.9rem;
    font-family: 'Roboto', sans-serif;
}

.user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right {
    display: flex;
    max-width: 50%;
}

.user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right .btn-group, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right .btn-group {
    width: 100%;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 0;
    justify-content: flex-end;
}

.user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2, .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2 {
    min-width: 20rem;
    margin: 0;
    padding: 7px 14px;
}

form.experience-form .form-group label {
    color: #8792A1;
}

.col-12.col-sm-12.col-md-12.add-your-projects {
    padding-top: 10px;
}

label.label-for-experience-fresher {
    color: #1e1e1e !important;
}

h4.page-heading-desc.experience-paragraph {
    font-weight: normal;
    font-size: 2.2rem;
    color: #4d5b66;
    padding-bottom: 15px;
}

.cstm-checkbox {
    display: flex;
    align-items: center;
    margin: 0;
}

.file-upload span {
    opacity: 1;
}

.file-upload {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle.area-of-interest {
    width: 100%;
}

.subtitle.add-your-social-links {
    width: 100%;
}

.checkmark {
    height: 30px;
    width: 30px;
}

.help-note {
    color: #4d5b66;
    padding-top: 10px;
}

.cstm-checkbox .checkmark:after {
    left: 12px;
}

.image-preview {
    font-weight: normal;
    color: #1e1e1e;
}


form.education-form .form-group {
    padding: 1.3rem 0;
}

.css-16pqwjk-indicatorContainer {
    color: #1e1e1e70 !important;
}

.select-wrapper::after {
    border: 0;
}

.progress-bottom-btn {
    width: 100%;
    padding-top: 2rem;
}

input#fresher {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid black
}

input#exp {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid black
}

label.label-for-experience-fresher::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #48194e;
    border-color: azure;
}

input#fresher:checked, input#exp:checked {
    background: #48194e;
}

.form-group.select-wrapper.personal-select-without-error select {
    background: transparent;
    background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 13px;
    padding-right: 2rem;
    background-size: 20px;
}

.form-group.select-wrapper.personal-select-without-error .education_select0 {
    background: transparent;
    background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 13px;
    padding-right: 2rem;
    background-size: 20px;
}

.cross-btn {
    top: 0;
    right: 0;
}

.row-wrapper {
    background-color: #fff;
    border: 1px solid #1e1e1e40;
}

.col-12.col-sm-6.col-md-6.resume {
    display: flex;
    align-items: center;
}

.col-12.col-sm-6.col-md-6.resume label {
    margin: 0;
}

form.assign-user-form.create-college-csv-form .form-group button {
    margin-top: 10px;
}

form.assign-user-form.create-college-csv-form .csv-upload-box {
    top: 0;
}

@media(max-width:767px) {
    .wrapper-container.signing-container::after, .wrapper-container.verify-email-container::after, .wrapper-container.verification-container::after {
        display: none;
    }

    .wrapper-container.signing-container .login-div {
        padding: 0;
        border: 0;
        margin: 0;
    }

    .signing-container .subtitle {
        width: 100%;
    }

    .wrapper-container.signing-container {
        align-items: flex-start;
    }

    .signing-container .btn-group {
        display: flex;
        justify-content: space-around;
    }

    .xenonstack-jobs-copyright {
        display: flex;
        align-items: baseline;
        gap: 3px;
    }

    .user-journey-form, .user-journey-form.user-journey-form-internal-steps {
        width: 100% !important;
    }

    .resend-otp {
        text-align: start;
    }

    .error-responsive, .do-later {
        text-align: start;
        padding-top: 1rem;
    }

    .otp-header .subtitle {
        margin: 0;
        width: 100%;
    }

    .user-journey-form.user-journey-form-internal-steps .btn-group {
        justify-content: flex-start;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right .btn-group, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right .btn-group {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right {
        padding-top: 10px;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2:nth-child(2), .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2:nth-child(2) {
        margin-top: 10px;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2, .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2 {
        padding: 5px 10px;
    }

    .subtitle {
        width: 100%;
    }

    section#bottom-btn button {
        margin-bottom: 2rem;
    }

    header.header-section.header-fixed.full-page-header .brand-logo-wrapper img.brand-logo {
        display: block !important;
    }
}
@media(min-height: 948px) {
    .wrapper-container.signing-container.personal-information{
        position: relative;
        padding: 5rem 0;
        height: 99vh;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;  
    }
    }

@media(max-width:1023px) {
    .signing-container .big-heading {
        font-size: 2.4rem;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .subtitle {
        width: 80%;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2, .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2 {
        width: 20rem;
        min-width: auto;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right {
        display: flex;
        max-width: 58%;
    }

    .col-12.col-sm-12.col-md-12.right .btn-group {
        width: 100%;
        display: flex;
        padding-top: 1.2rem;
        justify-content: space-between;
    }

}

@media(min-width:768px) and (max-width:1439px) {
    .section_header {
        margin: 0;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right .btn-group, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right .btn-group {
        gap: 15px;
    }
}

main.main-content-wrapper.auth-content-wrapper {
    padding-top: 0 !important;
}


@media (min-width: 1440px) and (max-width: 1440px) {
    .wrapper-container.signing-container,
    .wrapper-container.verify-email-container,
    .wrapper-container.verification-container,
    .wrapper-container.signing-container.personal-information,
    .wrapper-container.signing-container.education,
    .wrapper-container.signing-container.experience,
    .wrapper-container.signing-container.basic-information,
    .wrapper-container.signing-container.create-password {
        padding: 1.8rem 0;
    }
}

@media (min-width: 1440px) and (max-width: 1440px) {
    .wrapper-container.signing-container,
    .wrapper-container.verify-email-container,
    .wrapper-container.verification-container,
    .wrapper-container.signing-container.personal-information,
    .wrapper-container.signing-container.education,
    .wrapper-container.signing-container.experience,
    .wrapper-container.signing-container.basic-information,
    .wrapper-container.signing-container.create-password {
        padding: 1.8rem 0;
    }
}