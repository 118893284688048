.conditional_form_part {
    display: none;
}

.conditional_form_part_activator:checked+.conditional_form_part {
    display: block;
}

.personal-info-container,
.education-info-container,
.emergency-info-container,
.identity-info-container,
.work-experience-container {
    width: 75%;
    border: 1px solid rgb(190, 187, 187);
    margin-left: 190px;
    padding: 60px;
    padding-top: 40px;
}

.personal-info-container h2,
.education-info-container h2,
.emergency-info-container h2,
.identity-info-container h2,
.work-experience-container h2 {
    font-size: 28px;
    color: #59aaf7;
    font-weight: 100;
    background-color: #f5f9fd;
    padding: 40px;
}

Input.login-input {
    /* width of input fields for sign in */
    width: 100%;
}


Input.form-control-outer, select.form-control-outer {
    /*width of input fields that are not side by  side*/
    width: 700px;
}

.form-group.input-group {
    /*left margin for all input fiels in sign up*/
    margin-left: 120px;
}


.form-control {
    --default-font-size: 14px;
    --default-font-family: inherit;
    --default-border-width: 1px;
    --default-icon-size: 42px;
}

.form-group.input-group .input-password,
.form-group.input-group .input-confirm,
.form-group .input-inline {
    /*to display fields side by side and give them width*/
    display: inline-block;
    width: 30%;

}

.form-group.input-group .input-confirm,
.form-group .input-right {
    /*margin for input fields set at right side */
    /*float: right;*/
    margin-right: 20%;
    margin-left: 110px;
}

.form-group .input-right-textarea {
    /*margin for textarea fields that are at right side*/
    margin-left: 95px;
}

.form-group .input-right-radio {
    margin-right: 200px;
    /*margin for radio fields that are at right side*/
    margin-left: 110px;
}

textarea.form-control {
    width: 320px;
    height: 90px;
}

input[type="checkbox"].form-control {
    width: 20px;
    margin-left: 40px;
}

input[type="file"] .form-control {
    width: 22%;
    height: 50px;
}


.error {
    margin-left: 300px;
    font-size: 12px;
    color: red;
}

.btn-success {
    width: 16%;
}


.btn-group {
    /*left margin for the button group in sign up forms*/
    margin-right: 140px;
}

.btn-group button {
    background-color: #0F75E4;
    /* Green background */
    border: 1px solid white;
    /* Green border */
    color: white;
    /* White text */
    padding: 10px 24px;
    /* Some padding */
    cursor: pointer;
    /* Pointer/hand icon */
    float: right;
    /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
    border-right: none;
    /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
    content: "";
    clear: both;
    display: table;
}

/* Add a background color on hover */
.btn-group button:hover {
    background-color: #5b6a92;
}

.add-education-button:hover {
    background-color: rgb(231, 229, 229);
}

.add-education-button {
    background-color: white;
    color: rgb(32, 138, 209);
    cursor: pointer;
    border-radius: 2px;
    margin-left: 110px;
    border: 2px solid rgb(41, 166, 250);
    padding: 10px 18px;
}