.dashbaord-main-page {
    overflow: hidden;
    padding: 1rem;
}

a.pull-left.inline-block.mr-15.txt-light.collapsed img {
    width: 20px;
    position: absolute;
    left: 7px;
    top: 13px;
    display: block;
}

a.pull-left.inline-block.mr-15.txt-light img {
    width: 20px;
    position: absolute;
    left: 7px;
    top: 13px;
}

a.table-inside-btn.right.collapsed {
    border-radius: 5px;
    width: auto;
    min-width: 100px;
    border: none;
    display: inline-block;
    min-height: 42px;
    line-height: 42px;
    font-size: 13pt;
    color: #fff;
    text-align: center;
    background: #48194e;
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    padding: 0 2rem;
}

a.table-inside-btn.right.collapsed:hover {
    color: #48194e;
    background: #f3eaff;
}

.table-responsive a {
    color: #1890ff;
}

.panel-wrapper.collapse.in.panel-wrapper-display {
    opacity: 0;
    height: 0 !important;
}

img.zmdi.zmdi-chevron-down.down-arrow.down-arrow-rotate {
    transform: rotate(180deg);
}
.collapse.in {
    display: block;
    overflow: hidden;
    overflow-y: auto;
}

.internMessage p {
    position: relative;
    top: 20px;
    left: 250px;
    height: 50px;
}