.body-root {
  height: 100%;
}

.dashboard-body-wrapper.full-height {
  height: 100%;
}


.route-wrapper {
  height: 100%;
}

.dashboard-content-body {
  margin-left: 230px;
  padding-top: 50px;
  display: block;
  min-height: 100vh;
  position: relative;
  padding-bottom: 30px;
  -webkit-transition: margin 0.4s;
  transition: margin 0.4s;
  background-color: #fcfcfc;
}

.main-content-wrapper {
  display: block;
}

.main-content-wrapper.employee-dashboard_wrapper {
  padding: 50px 0;
}

.main-content-wrapper.employee-profile-wrapper {
  padding: 50px 0;
}

.dashboard-content-container {
  padding: 20px;
}

.dashboard-content-body.closed {
  margin-left: 60px;
}

.dashboard-body-wrapper {
  display: block;
  padding: 20px 0;
}

.content-section-wrapper {
  position: relative;
}

.content-section-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(197, 211, 223, .49);
  display: block;
  padding: 15px;
}

/* custom list */
.custom-list-item {
  margin: 0 0 12px;
}

.custom-list-item-content {
  border: 1px solid #eee;
  padding: 10px 12px;
  background-color: #fff;
  position: relative;
}

.custom-list-sub-item-content .list-content-title,
.custom-list-item-content .assigned-reviewer-name {
  font-size: 14px;
  margin: 0;
  word-break: break-word;
}

.custom-list-sub-item-content .list-content-description,
.custom-list-item-content .assigned-reviewer-email {
  color: #757575;
  font-size: 12px;
  margin: 0;
}

.list-content-meta-info {
  color: #757575;
  font-size: 13px;
  margin: 0;
}

.custom-list-item-content:hover {
  box-shadow: 0 4px 8px #f3eaff40;
  border-color: #aa15ba!important;
}

.custom-list-sub-item-content .list-content-title a {
  color: inherit;
}

.custom-list-sub-item-content .list-content-title a:hover {
  text-decoration: underline;
  color: var(--theme-primary-color);
}

.custom-list-item-content:hover .list-content-title a {
  text-decoration: underline;
  color: var(--theme-primary-color);
}

.full-item-content-link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  cursor: pointer;
  bottom: 0;
  z-index: 999;
}

.custom-list-item-label {
  color: #999;
  font-size: 12px;
  display: block;
  margin: 0;
}

.custom-list-item-value {
  margin: 0;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.custom-list-item-value a{
  position: relative;
}
/* custom-tabs */

.react-tabs-section-wrapper .react-tabs__tab-list {
  border-bottom: 2px solid #dddddd;
  margin: 0 0 12px;
  padding: 0;
}

.react-tabs-section-wrapper .react-tabs__tab {
  display: inline-block;
  border-top: none;
  border-bottom: 2px solid transparent;
  bottom: -2px;
  position: relative;
  list-style: none;
  padding: 8px 12px;
  cursor: pointer;
  border-left: none;
  border-right: none;
  color: #666666;
}

.react-tabs-section-wrapper .react-tabs__tab--selected {
  background: inherit;
  border-color: var(--theme-primary-color2);
  color: var(--theme-primary-color2);
  border-radius: 0;
}

.react-tabs-section-wrapper .react-tabs__tab:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--theme-primary-color2);
  color: var(--theme-primary-color2);
  outline: none;
}

.react-tabs-section-wrapper .react-tabs__tab:focus::after {
  content: unset;
}

.admin-user-profile-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.admin-user-profile-header {
  margin: 0 0 12px;
}

.custom-tabs-navigation-links {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
  margin: 0 -5px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 2px solid #eee;
}

.custom-tabs-navigation-item-content {
  color: #666;
  padding: 8px 12px;
  display: block;
  position: relative;
}

.custom-tabs-navigation-item-content:hover,
.custom-tabs-navigation-item-content:focus,
.custom-tabs-navigation-item-content.active {
  color: var(--theme-primary-color2);
}

.custom-tabs-navigation-item-content::after {
  position: absolute;
  content: "";
  bottom: -2px;
  height: 2px;
  background-color: var(--theme-primary-color2);
  width: 0%;
  left: 0;
  transition: width 0.4s ease;
}

.custom-tabs-navigation-item-content:hover::after,
.custom-tabs-navigation-item-content:focus::after,
.custom-tabs-navigation-item-content.active::after {
  color: var(--theme-primary-color2);
  width: 100%;
}

.custom-tabs-navigation-item {
  margin: 0 5px;
}

.custom-tabs-navigation {
  margin: 0 0 16px;
}

/* status label */
.label-status {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  background-color: #eaeaea;
  font-size: 12px;
  outline: none;
}

.label-status.status-complete {
  background-color: #00A65A;
  color: #fff;
  border-color: #00A65A;
}

.label-status.status-in-complete {
  background-color: #DD4B39;
  color: #fff;
  border-color: #DD4B39;
}

.label-status.status-in-progress {
  background-color: #407cf5;
  color: #fff;
  border-color: #407cf5;
}