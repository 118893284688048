.xd-modal {
    z-index: 999;
    overflow: hidden;
    display: none;
    align-items: flex-start;
}
.xd-modal, .xd-modal-overlay {
    /* position:absolute; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.xd-modal.show {
    /* display: block; */
    display: flex;
}
.xd-modal-overlay {
    background: rgba(0,0,0,.3);
}
.xd-modal-dialog {
    max-width: 100%;
    width: 450px;
    margin: 3rem auto;
}
.xd-modal.modal-centered {
    align-items: center;
}
.xd-modal.modal-sm .xd-modal-dialog {
    width: 300px;
}
.xd-modal.modal-lg .xd-modal-dialog {
    width: 850px;
}
.xd-modal.modal-lg .xd-modal-dialog .xd-modal-title {
    font-size: 1.4rem;
}
.xd-modal-content {
    background: 
    #fff;
    position: relative;
    z-index: 999;
    border-radius: 4px;
}
.xd-modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}
.xd-modal-title {
    font-size: 1.2rem;
    margin: 0;
}
.xd-modal-close {
    position: absolute;
    background: transparent;
    width: 22px;
    height: 23px;
    right: 5px;
    top: 7px;
    border: none;
    padding: 0;
    font-size: 1.5rem;
    line-height: 0px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 100%;
    z-index: 1;
}
.xd-modal-close:hover {
    background-color: var(--theme-lighter-color);
    color: var(--theme-primary-color);
}
.xd-modal-body {
    padding: 1.5rem 1rem;
}
.xd-modal-footer {
    padding: 1rem;
    border-top: 1px solid 
    #e8e8e8;
}
@media screen and (max-width:400px) {
    .xd-modal-footer .btn {
        width: 100%;
        margin: 0 0 1rem;
    }
}
.xd-modal-content-status {
    background: 
    #fff;
    position: absolute;
    z-index: 999;
    border-radius: 4px;
}
.xd-modal-header-status {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}
.xd-modal-body-status {
    padding: 1.5rem 1rem;
}
.xd-modal-footer-status {
    padding: 1rem;
    border-top: 1px solid 
    #e8e8e8;
    margin-bottom: -16px;
}
@media screen and (max-width:400px) {
    .xd-modal-footer .btn {
        width: 100%;
        margin: 0 0 1rem;
    }
}
.required-status{
    margin-bottom: 0px;
}
.user-status{
    margin-bottom:  0px;
}