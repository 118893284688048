
    
    .table-container{
      text-align: left;
      margin-right:30px;
    }
    
    .listing-table{
     width: auto;
      border-collapse: collapse;
      letter-spacing: 1px;
      font-size: 1.0rem;
      display:inline-block;
      background-color: white;
      
    }
    .listing-table tr:hover {background-color: #f5f8fa;}
    .listing-table td{
      /*border: 1px solid rgb(190,190,190);*/
      padding: 6px 10px;
      border-bottom: 1px solid rgb(235, 229, 229);
      vertical-align: middle;
      text-align: left;
    }

    .listing-table th {
      background-color: rgb(235,235,235);
      padding:9px 5px;
      width: 120px;
    }

    .tableHeader{
      background-color: rgb(235,235,235);
    }
    
    .content{
      color: black !important;;
      font-size: 14px !important;
      align-self: center;
    }
    
    
    /*tr:nth-child(even) td {
      background-color: rgb(250,250,250);
    }
    
    tr:nth-child(odd) td {
      background-color: rgb(245,245,245);
    }*/
    .content.intern-name{
       /*250px*/
      color: #4c5d75;
    }
    
    .content.intern-name:hover{
      color:#2a3bad;
      background-color: rgb(223, 222, 222);
    }
    .intern-name{
      width: 230px !important;;
    }

    .email{
        width: 230px !important;; /*350px*/
    }
    .task, .scoring{
        width :130px;
    }
    .avatar{
      width: 220px;
    }
    @media(max-width:1023px)
    {
      .avatar{
        width: 80px;
      }
    }
    .actions{
      width:100px;
    }

    .email, .task, .scoring, .avatar, .actions{
      word-break: break-word;
    }
    /*.more{
      width:100px;
    }*/

    /*Dropdown{
      --theme-primary-color: #0f1013;
    --theme-secondary-color: #4e4a46;
    }*/
    
    .topnav {
        width:97%;
        overflow: hidden;
        /*background-color: #e9e9e9;*/
      }
      
      .topnav a {
        float: left;
        display: block;
        color: rgb(121, 118, 118);
        text-align: center;
        padding: 6px 8px;
        text-decoration: none;
        font-size: 14px;
        border-bottom: 2px solid rgb(228, 225, 225);
        font-weight: bolder;
      }
      
      .topnav a:hover {
        background-color: rgb(224, 222, 222);
        color: black;

      }
      
      .topnav a.active {
        /*background-color: #80bff1;*/
        color:#bf1f67;          /*#0979d4;*/
        border-bottom: 4px solid #bf1f67;   /*#128cf0 ;*/
        font-weight: bolder;
      }
      .intern-navigation-section{
        display:flex;
        justify-content: flex-start;
        flex-wrap:wrap;
      }

      .module-table-header, .module-table-data{
        text-align: left;
      }
      
      .topnav .search-input {
        float: right;
        
      }
      .topnav .export-btn{
        float:right;
        padding-right:20px;
      }
      .topnav a.btn{
        border-bottom:1px solid #bf1f67;
        color:#bf1f67;
      }
      .topnav .add-employee-btn{
        float:right;
      }
      
      .topnav input[type=text] {
        
        font-size: 14px;
        border: none;
      }
      
     /*}.topnav .search-container button {
        float: right;
        padding: 2px 4px;
        margin-top: 2px;
        margin-right: 10px;
        font-size: 14px;
        border: none;
        cursor: pointer;
      }*/
      
      .topnav .search-container button:hover {
        background: #ccc;
      }
      
      @media screen and (max-width: 600px) {
        .topnav .search-container {
          float: none;
        }
        .topnav a, .topnav input[type=text], .topnav .search-container button {
          float: none;
          display: block;
          text-align: left;
          width: 100%;
          margin: 0;
          padding: 14px;
        }
        .topnav input[type=text] {
          border: 1px solid #ccc;  
        }
    }

    .container-bar {
      cursor: pointer;
      float: right;
      text-align:center;
    }
    
    .bar1, .bar2, .bar3 {
      width: 4px;
      height: 4px;
      background-color: rgb(138, 135, 135);
      margin: 6px 0;
      transition: 0.4s;
      border-radius: 500px;
    }
    
    /* Rotate first bar */
    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
      transform: rotate(-45deg) translate(-9px, 6px) ;
    }
    
    /* Fade out the second bar */
    .change .bar2 {
      opacity: 0;
    }
    
    /* Rotate last bar */
    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
      transform: rotate(45deg) translate(-8px, -8px) ;
    }

    .deopdown-container {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      text-align: center;
  }
  

  /* More Button / Dropdown Menu */
  
  .more-btn,
  .more-menu-btn {
      background: none;
      border: 0 none;
      line-height: normal;
      overflow: visible;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      width: 100%;
      text-align: left;
      outline: none;
      cursor: pointer;
  }
  
  .more-dot {
      background-color: #aab8c2;
      margin: 0 auto;
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 1px;
      border-radius: 50%;
      transition: background-color 0.3s;
  }
  
  .more-menu {
      position: absolute;
      top: 100%;
      z-index: 900;
      float: left;
      padding: 10px 0;
      margin-top: 9px;
      background-color: #fff;
      border: 1px solid #ccd8e0;
      border-radius: 4px;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
      opacity: 0;
      transform: translate(0, 15px) scale(.95);
      transition: transform 0.1s ease-out, opacity 0.1s ease-out;
      pointer-events: none;
      width: 100px;
  }
  
  .more-menu-caret {
      position: absolute;
      top: -10px;
      left: 12px;
      width: 18px;
      height: 10px;
      float: left;
      overflow: hidden;
  }
  
  .more-menu-caret-outer,
  .more-menu-caret-inner { 
      position: absolute;
      display: inline-block;
      margin-left: -1px;
      font-size: 0;
      line-height: 1;
  }
  
  .more-menu-caret-outer {
      border-bottom: 10px solid #c1d0da;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      height: auto;
      left: 0;
      top: 0;
      width: auto;    
  }
  
  .more-menu-caret-inner {
      top: 1px;
      left: 1px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #fff;
  }
  
  .more-menu-items {
      margin: 0;
      list-style: none;
      padding: 0;
  }
  
  .more-menu-item {
      display: block;
  }
  
  .more-menu-btn {
      min-width: 100%;
      color: #66757f;
      cursor: pointer;
      display: block;
      font-size: 13px;
      line-height: 18px;
      padding: 5px 20px;
      position: relative;
      white-space: nowrap;
  }
  
  .more-menu-item:hover {
      background-color: #489fe5;
  }
  
  .more-menu-item:hover .more-menu-btn {
      color: #fff;
  }
  
  .more-btn:hover .more-dot,
  .show-more-menu .more-dot {
      background-color: #516471;
  }
  
  .show-more-menu .more-menu {
      opacity: 1;
      transform: translate(0, 0) scale(1);
      pointer-events: auto;
  }

  .btn.btn-compact.btn-outline {
    --theme-primary-color: #bf1f67;
    --theme-secondary-color: #bf1f67;
    --theme-light-color: #f1abcb;
    --default-font-size:12px;
    --default-font-family:inherit;
    --default-border-width:2px;
    --button-icon-size:30px; 
 }

 .account-info-container, .personal_info, .education_info, .other_info{
   background-color: white;
   margin-left:30px;
   padding: 50px;
   width:900px;
   margin-right:30px;
   box-shadow:  10px 3px 22px -16px rgba(0,0,0,0.75);
 }

 .details-header{
   /*background-color: rgb(248, 240, 242);*/
   border-bottom: 1px solid rgb(207, 206, 206);
   font-size: 18px;
   font-weight: bolder;
   color:#bf1f67;
 }

 .details td{
   width:150px;
   height:37px;
   word-break: break-word;
 }

 .detail-name{
   font-weight: bolder;
   color:rgb(99, 96, 96);
 }
 .account-info{
   float:right;
 }
.no-data{
  margin-left:60px;
  margin-top:30px;
  text-align:center;
}
.pagination-container{
text-align: left;
padding-left:30px;
margin: 0 auto;
}
@media (min-width: 1025px) {
  .pagination-container{
  max-width: 1170px; 
  }
}
@media (min-width: 1369px) {
  .pagination-container{ 
  max-width: 1340px;
  }
}
@media (min-width: 1441px) {
  .pagination-container{ 
  max-width: 1400px;
  }
}

.module-link-name-color{
  color:rgb(87, 84, 84);
}
.module-link-name-color:hover{
color:darkblue;
}
.dropdown .dropdown-menu li .dropdown-link{
  font-size:13px;
}